import React from 'react';
import './App.css';
import Homepage from './Pages/Homepage';

function App() {
  return (
  <Homepage />
  );
};


export default App;
